import React from 'react'

// import skull from '../images/global/skull.svg'

import Root from '../components/Root'
import SVG from '../components/SVG'

const NotFound = () => {
    return (
        <Root>
            <div className="py-12 lg:py-20 text-center">
                <div className="xl:container mx-auto px-8 md:px-10 lg:px-20 xl:px-32">
                    {/* <SVG
                        src={skull}
                        className="w-12 h-auto mx-auto text-red-light"
                    /> */}
                    <h1 className="mt-8 mb-4 text-3xl lg:text-4xl text-black font-light">
                        Error 404, page not found.
                    </h1>
                    <p className="max-w-lg w-full mx-auto text-sm">
                        Sorry, but we could not find the page you were looking
                        for. It has either changed URL or been removed. Use the
                        main website navigation to continue browsing this
                        website or contact us directly on{' '}
                        <a
                            href="tel:01788818670"
                            className="font-bold text-blue-light hover:underline"
                        >
                            01788 818 670
                        </a>
                        .
                    </p>
                </div>
            </div>
        </Root>
    )
}

export default NotFound
